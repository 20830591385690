import { ShadowFilter } from './ShadowFilter'

export type AccessibleSVGProps = {
  label: string
  circle?: boolean
  circleProps?: React.SVGProps<SVGCircleElement>
  shadow?: boolean
  shadowProps?: React.SVGProps<SVGFEDropShadowElement>
} & React.SVGProps<SVGSVGElement>

/**
 * Accessible SVG component providing a title element for
 * screen readers and a shadow filter.
 *
 * Note:
 * Use `filter={props.shadow ? 'url(#shadowFilter)' : ''}` html attribute on
 * the element/path you want to apply the shadow to.
 * Label is required for accessibility.
 * If the icon truly doesn't need a label, use an empty string and make the icon aria-hidden
 */
export const AccessibleSVG: React.FC<AccessibleSVGProps> = ({
  children,
  label,
  circle,
  circleProps,
  shadow,
  shadowProps,
  ...props
}) => {
  return (
    <svg role="img" {...props}>
      {label !== undefined && <title>{label}</title>}
      {circle && (
        <circle
          cx="50%"
          cy="50%"
          r="50%"
          fill="rgba(64 64 64 / 50%)"
          {...circleProps}
        />
      )}
      {shadow && <ShadowFilter {...shadowProps} />}
      {children}
    </svg>
  )
}
