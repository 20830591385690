import React from 'react'
import { useTranslation } from 'react-i18next'

import { css } from '@emotion/react'
import type { NextPage, NextPageContext } from 'next'

import Channel from '@microsites/components/Channel/Channel'
import MicrositePage from '@microsites/components/MicrositePage'
import type { IMicrositePageProps } from '@microsites/types'
import { getMicrositeInitialProps } from '@microsites/utils/microsites/getMicrositeInitialProps'
/**
 *
 */
const Home: NextPage<IMicrositePageProps> = (props) => {
  const { ssrChannel, ssrPage } = props
  const { t } = useTranslation()

  if (ssrPage) {
    // this is for localhost:3000/?channelOrPage={channelName}
    return <MicrositePage {...props} />
  }
  if (ssrChannel) {
    // This is a request on a username subdomain like sparky.fw.tv
    // Page content is returned in getLayout
    return <Channel {...props} />
  }

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 2rem;
        width: 100vw;
        height: 100vh;
        align-items: center;
        justify-content: center;
        text-align: center;
      `}
    >
      <a href="https://firework.com">
        <img
          alt={t('next:{{firework}} logo', {
            firework: 'Firework',
          })}
          src="/images/FireworkLogo-RGBWhite.svg"
          css={css`
            width: 100%;
            max-width: 350px;
          `}
        />
      </a>
      <div>
        {t('next:Continue to')}{' '}
        <a
          href="https://firework.com"
          css={css`
            text-decoration: underline;
          `}
        >
          {'Firework.com'}
        </a>
      </div>
    </div>
  )
}

Home.getInitialProps = async (
  context: NextPageContext,
): Promise<IMicrositePageProps> => {
  return await getMicrositeInitialProps(context)
}

export default Home
