import { Fragment, lazy, Suspense } from 'react'

import type { InjectableSVGProps } from './InjectableSVG'

export type { AccessibleSVGProps } from './AccessibleSVG'
export { AccessibleSVG } from './AccessibleSVG'
export { ShadowFilter } from './ShadowFilter'

const InjectableSVGComponent = lazy(() => import('./InjectableSVG'))

/**
 * Lazy loaded version of InjectableSVG component.
 */
export const InjectableSVG: React.FC<InjectableSVGProps> = (props) => (
  <Suspense fallback={<Fragment />}>
    <InjectableSVGComponent {...props} />
  </Suspense>
)
