import type { PropsWithChildren } from 'react'

import type { SerializedStyles } from '@emotion/react'
import { css } from '@emotion/react'

import { propWithBreakpoints } from '../MediaQuery'

/**
 *
 * @param param0
 * @returns
 */
export const Container: React.FC<
  PropsWithChildren<{ css?: Array<SerializedStyles> }>
> = ({ children, css: extendingCss, ...props }) => {
  return (
    <div
      css={[
        css`
          margin: 0 auto;
          width: 100%;
        `,
        propWithBreakpoints('max-width', [
          '100%',
          '100%',
          '720px',
          '960px',
          '1140px',
        ]),
        ...(extendingCss || []),
      ]}
      {...props}
    >
      {children}
    </div>
  )
}
